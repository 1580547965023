function FinalPlayer({
	player,
	isSelected,
	countFinalPlayers,
	updateFinalPlayerSelected,
	reintegrateFinalPlayer,
	picture,
}) {
	return (
		<div
			onClick={() =>
				!player.isFinalEliminate
					? updateFinalPlayerSelected(player.id)
					: void 1
			}
			className={
				"final-player-container-" +
				countFinalPlayers +
				" final-player-container " +
				(isSelected ? " final-player-selected" : "")
			}
		>
			<div className="final-player-box-name">&nbsp;</div>
			<div className="final-player-name">{player.firstName}</div>
			<div
				className="profil-image"
				style={{ backgroundImage: `url("${picture}")` }}
			>
				{player.isFinalEliminate && (
					<div className="eliminate-bg"></div>
				)}
			</div>
			{player.isFinalEliminate && (
				<button
					onClick={() =>
						reintegrateFinalPlayer(player.id, player.firstName)
					}
					className="reinsert-player-button"
				>
					Réintégrer
				</button>
			)}
		</div>
	);
}

export default FinalPlayer;
