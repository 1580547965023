import axios from "axios";

const SelectFinalChoice = async (gameAppCode, challengeId) => {
	try {
		const resp = await axios.patch(
			process.env.REACT_APP_MAIN_API +
				"/api/game-play/player/final-choice/" +
				gameAppCode +
				"/" +
				challengeId
		);
		return resp.data;
	} catch (err) {
		throw new Error("Impossible d'ajouter ce défi à la partie");
	}
};

export default SelectFinalChoice;
