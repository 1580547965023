const ResolvePlayersSelected = (
	playersSelected,
	playerId,
	gameMode,
	playersGroupedTeam
) => {
	let playersSelectedDatas = [...playersSelected];
	if (gameMode === "TEAM") {
		if (playersSelectedDatas.includes(playerId)) {
			playersGroupedTeam[playerId].forEach((playerIdByTeam) => {
				playersSelectedDatas = playersSelectedDatas.filter(
					(playersSelectedId) => playersSelectedId !== playerIdByTeam
				);
			});
		} else {
			playersSelectedDatas = [
				...playersSelectedDatas,
				...playersGroupedTeam[playerId],
			];
		}
	} else {
		if (playersSelectedDatas.includes(playerId)) {
			playersSelectedDatas = playersSelectedDatas.filter(
				(playersSelectedId) => playersSelectedId !== playerId
			);
		} else {
			playersSelectedDatas.push(playerId);
		}
	}
	return playersSelectedDatas;
};

export default ResolvePlayersSelected;
