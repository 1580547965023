import axios from "axios";

const StartFinalGame = async (gameAppCode, date) => {
	try {
		const resp = await axios.patch(
			process.env.REACT_APP_MAIN_API +
				"/api/game-play/player/game-master/" +
				gameAppCode +
				"/start/final",
			{
				date: date,
			}
		);
		return resp.data;
	} catch (err) {
		throw new Error("Impossible de démarrer la finale");
	}
};

export default StartFinalGame;
