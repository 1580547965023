import "./Main.css";
import { useEffect, useState } from "react";
import Button from "./Component/Button/Button";
import ListPlayers from "./Component/ListPlayers/ListPlayers";
import Loading from "./Component/Loading/Loading";
import LoadGame from "./Api/LoadGame";
import PostScoring from "./Api/PostScoring";
import FadeMsg from "./Component/FadeMsg/FadeMsg";
import ResolvePlayersGroupedTeam from "./Utils/ResolvePlayersGroupedTeam";
import ResolvePlayersSelected from "./Utils/ResolvePlayersSelected";
import Header from "./Component/Header/Header";
import FinalChoice from "./Component/FinalChoice/FinalChoice";
import SelectFinalChoice from "./Api/SelectFinalChoice";
import UnlockTabletGameMaster from "./Api/UnlockTabletGameMaster";
import FinalPlayer from "./Component/Player/FinalPlayer";
import StartFinalGame from "./Api/StartFinalGame";
import ResolveFinalPlayersSelected from "./Utils/ResolveFinalPlayersSelected";
import EliminatePlayers from "./Api/EliminatePlayers";
import ReintegratePlayer from "./Api/ReintegratePlayer";
import InputAppCode from "./Component/InputAppCode/InputAppCode";
import AllWinners from "./Api/AllWinners";

function App() {
	const [isLoading, setIsLoading] = useState(true);
	const [gameState, setGameState] = useState(null);
	const [config, setConfig] = useState(null);
	const [gameMode, setGameMode] = useState("SOLO");
	const [players, setPlayers] = useState([]);
	const [playersPictures, setPlayersPictures] = useState({});
	const [winnersSelected, setWinnersSelected] = useState([]);
	const [losersSelected, setLosersSelected] = useState([]);
	const [isBtnActive, setIsBtnActive] = useState(false);
	const [displayScoringMsg, setDisplayScoringMsg] = useState(false);
	const [displayBoxMsg, setDisplayBoxMsg] = useState(false);
	const [displayFadeBox, setDisplayFadeBox] = useState(false);
	const [playersGroupedTeam, setPlayersGroupedTeam] = useState({});
	const [finalChallenges, setFinalChallenges] = useState([]);
	const [finalChallengeSelected, setFinalChallengeSelected] = useState(null);
	const [firstPlayer, setFirstPlayer] = useState(null);
	const [screen, setScreen] = useState(null);
	const [codeGameMaster, setCodeGameMaster] = useState("");
	const [finalPlayers, setFinalPlayers] = useState([]);
	const [finalPlayersIds, setFinalPlayersIds] = useState([]);
	const [finalPlayersSelected, setFinalPlayersSelected] = useState([]);
	const [displayFinalChoiceSelectedMsg, setDisplayFinalChoiceSelectedMsg] =
		useState(false);

	const searchParams = new URLSearchParams(document.location.search);
	const appCode = searchParams.get("g");

	/* ********************************************************************************** */
	/* LOAD GAME */
	/* ********************************************************************************** */
	useEffect(() => {
		if (appCode !== null) {
			const referrer = document.referrer;
			// TODO : Check referrer : à tester sur le serveur avec la redirection par qrCode
			if (true) {
				loadGame();
			}
		} else {
		}
	}, []);

	const loadGame = async () => {
		const data = await LoadGame(appCode);
		setGameState(data.game.state);
		setConfig(data.config);
		setGameMode(data.game.mode);
		if (data.hasOwnProperty("scenario")) {
			resolvePlayersGroupedTeam(data.game.mode, data.scenario);
		}
		resolvePlayers(data.players);
		setFirstPlayer(data.players[0] ?? null);
		setFinalChallenges(data.game.finalChallenges);
		resolveStateUi(data.game.state, false);
		if (data.hasOwnProperty("finalPlayers")) {
			resolveFinalPlayers(data.finalPlayers);
		}
	};

	/* ********************************************************************************** */
	/* SCORING */
	/* ********************************************************************************** */
	useEffect(() => {
		setIsBtnActive(
			winnersSelected.length > 0 && losersSelected.length > 0
				? true
				: false
		);
	}, [winnersSelected, losersSelected]);

	const handleClickButtonSetAppCode = (appCodeText) => {
		window.location.href = "?g=GAME-" + appCodeText;
	};

	const handleClickButton = (e) => {
		e.preventDefault();
		if (
			screen === "scoring" &&
			winnersSelected.length > 0 &&
			losersSelected.length > 0
		) {
			postScoring();
		} else if (
			screen === "finalChoice" &&
			finalChallengeSelected !== null
		) {
			selectFinalChallenge();
		} else if (screen === "codeGameMaster" && codeGameMaster !== "") {
			unlockTabletGameMaster();
		} else if (screen === "final" && finalPlayersSelected.length > 0) {
			eliminateFinalPlayers();
		}
	};

	const postScoring = async () => {
		setIsLoading(true);
		await PostScoring(appCode, winnersSelected, losersSelected);
		setIsLoading(false);
		setWinnersSelected([]);
		setLosersSelected([]);
		setDisplayScoringMsg(true);
		setDisplayFadeBox(true);
		setTimeout(() => {
			setDisplayScoringMsg(false);
			setDisplayFadeBox(false);
		}, 1500);
	};

	const updatePlayerSelected = (playerId, page) => {
		if (page === "winners") {
			const winnersSelectedDatas = ResolvePlayersSelected(
				winnersSelected,
				playerId,
				gameMode,
				playersGroupedTeam
			);
			setWinnersSelected(winnersSelectedDatas);
		} else if (page === "losers") {
			const losersSelectedDatas = ResolvePlayersSelected(
				losersSelected,
				playerId,
				gameMode,
				playersGroupedTeam
			);
			setLosersSelected(losersSelectedDatas);
		}
	};

	/* ********************************************************************************** */
	/* FINAL CHOICE */
	/* ********************************************************************************** */
	const selectFinalChoice = (challenge) => {
		if (challenge) {
			setFinalChallengeSelected(challenge);
		}
	};

	const selectFinalChallenge = async () => {
		await SelectFinalChoice(appCode, finalChallengeSelected.id);
		setIsBtnActive(false);
		setDisplayFinalChoiceSelectedMsg(true);
		setDisplayFadeBox(true);
		setTimeout(() => {
			setDisplayFinalChoiceSelectedMsg(false);
			setDisplayFadeBox(false);
			setFinalChallengeSelected(null);
		}, 4500);
	};

	useEffect(() => {
		setIsBtnActive(finalChallengeSelected !== null);
	}, [finalChallengeSelected]);

	/* ********************************************************************************** */
	/* GAME MASTER */
	/* ********************************************************************************** */

	useEffect(() => {
		if (codeGameMaster.length === 3) {
			setIsBtnActive(true);
		} else {
			setIsBtnActive(false);
		}
	}, [codeGameMaster]);

	const unlockTabletGameMaster = async () => {
		await UnlockTabletGameMaster(appCode, codeGameMaster);
		localStorage.setItem("unlock-" + appCode, "OK");
		setScreen("final");
		setIsBtnActive(false);
	};

	const startFinal = async () => {
		const startDateFinalGame = new Date();
		const year = startDateFinalGame.getFullYear();
		const month = startDateFinalGame.getMonth() + 1;
		const day = startDateFinalGame.getDate();
		const hours = startDateFinalGame.getHours();
		const minutes = startDateFinalGame.getMinutes();
		const seconds = startDateFinalGame.getSeconds();
		const date =
			String(year) +
			"-" +
			String(month).padStart(2, "0") +
			"-" +
			String(day).padStart(2, "0") +
			" " +
			String(hours).padStart(2, "0") +
			":" +
			String(minutes).padStart(2, "0") +
			":" +
			String(seconds).padStart(2, "0");
		await StartFinalGame(appCode, date);
	};

	const allWinners = async () => {
		if (
			window.confirm(
				"Voulez-vous vraiment désigner tous les finalistes restants comme vainqueurs de la partie ?"
			)
		) {
			await AllWinners(appCode);
		}
	};

	const updateFinalPlayerSelected = (playerId) => {
		const finalPlayersSelectedDatas = ResolveFinalPlayersSelected(
			finalPlayersSelected,
			playerId
		);
		setFinalPlayersSelected(finalPlayersSelectedDatas);
	};

	useEffect(() => {
		setIsBtnActive(finalPlayersSelected.length > 0 ? true : false);
	}, [finalPlayersSelected]);

	const eliminateFinalPlayers = async () => {
		await EliminatePlayers(appCode, finalPlayersSelected);
		setIsBtnActive(false);
		setFinalPlayersSelected([]);
	};

	const reintegrateFinalPlayer = async (playerId, playerName) => {
		if (window.confirm("Voulez-vous vraiment réintégrer " + playerName)) {
			await ReintegratePlayer(appCode, playerId);
		}
	};

	/* ********************************************************************************** */
	/* RESOLVER */
	/* ********************************************************************************** */
	const resolvePlayers = (dataPlayers) => {
		const dataPlayersPictures = dataPlayers.reduce((acc, onePlayer) => {
			acc[onePlayer.id] = onePlayer.picture;
			return acc;
		}, {});
		setPlayers(dataPlayers);
		setPlayersPictures(dataPlayersPictures);
	};

	const resolvePlayersGroupedTeam = (mode, scenario) => {
		const items = ResolvePlayersGroupedTeam(mode, scenario);
		if (items !== false) {
			setPlayersGroupedTeam(items);
		}
	};

	const resolveFinalPlayers = (dataFinalPlayers) => {
		const dataFinalPlayersIds = dataFinalPlayers.reduce(
			(acc, onePlayer) => {
				acc.push(onePlayer.id);
				return acc;
			},
			[]
		);
		setFinalPlayers(dataFinalPlayers);
		setFinalPlayersIds(dataFinalPlayersIds);
	};

	const resolveStateUi = (state, reloadGame) => {
		if (state === "WAITING_GAME") {
			if (reloadGame) {
				loadGame();
			}
			setIsLoading(false);
			setDisplayFadeBox(true);
			setDisplayBoxMsg(true);
			setScreen("fadeBox");
		} else if (state === "IN_PROGRESS" || state === "DEMO") {
			setIsLoading(false);
			setDisplayFadeBox(false);
			setDisplayBoxMsg(false);
			setScreen("scoring");
		} else if (state === "WAITING_FINAL_CHOICE") {
			setIsLoading(false);
			setDisplayFadeBox(true);
			setDisplayBoxMsg(true);
			setScreen("fadeBox");
			setWinnersSelected([]);
			setLosersSelected([]);
		} else if (state === "FINAL_CHOICE") {
			setIsLoading(false);
			setDisplayFadeBox(false);
			setDisplayBoxMsg(false);
			setScreen("finalChoice");
		} else if (state === "FINAL_CHALLENGE_SELECTED") {
			setIsLoading(false);
			setDisplayBoxMsg(false);
			if (localStorage.getItem("unlock-" + appCode) === "OK") {
				setScreen("final");
			} else {
				setScreen("codeGameMaster");
			}
		} else if (state === "IN_FINAL_PROGRESS") {
			setIsLoading(false);
			setDisplayBoxMsg(false);
			setScreen("final");
		} else if (state === "POST_GAME" || state === "FINISHED") {
			setIsLoading(false);
			setDisplayFadeBox(true);
			setDisplayBoxMsg(true);
			setScreen("fadeBox");
		}
	};

	/* ********************************************************************************** */
	/* MERCURE EVENT */
	/* ********************************************************************************** */
	useEffect(() => {
		if (config) {
			const url = new URL(config.MERCURE_URL);
			url.searchParams.append(
				"topic",
				process.env.REACT_APP_MERCURE_TOPIC_URI + appCode
			);
			const eventSource = new EventSource(url);
			eventSource.onmessage = (e) => {
				const data = JSON.parse(e.data);
				resolveMercureEvent(data);
			};
		}
	}, [config]);

	const resolveMercureEvent = (data) => {
		if (data.state) {
			setGameState(data.state);
		}
		if (
			[
				"UPDATE_STATE",
				"START_GAME",
				"END_GAME",
				"START_DEMO",
				"FINAL_CHALLENGE_SELECTED",
				"START_FINAL_GAME",
				"FINAL_PLAYERS_ELIMINATE",
				"FINAL_PLAYER_REINTEGRATE",
			].includes(data.event)
		) {
			resolveStateUi(data.state, true);
			if (
				data.finalPlayers &&
				((data.state === "WAITING_FINAL_CHOICE" &&
					data.event === "END_GAME") ||
					data.event === "FINAL_PLAYERS_ELIMINATE" ||
					data.event === "FINAL_PLAYER_REINTEGRATE")
			) {
				resolveFinalPlayers(data.finalPlayers);
			}
		} else if (data.event === "START_EVENT") {
			setGameMode(data.scenario.mode);
			resolvePlayersGroupedTeam(data.scenario.mode, data.scenario);
		} else if (data.event === "END_EVENT") {
			setGameMode("SOLO");
			resolvePlayersGroupedTeam({});
		}
	};

	if (appCode === null) {
		return (
			<main>
				<InputAppCode
					handleClickButtonSetAppCode={handleClickButtonSetAppCode}
				/>
			</main>
		);
	}

	/* ********************************************************************************** */
	/* RENDER */
	/* ********************************************************************************** */
	return (
		<main className={"main-" + gameState}>
			{isLoading ? (
				<Loading state={gameState} />
			) : (
				<>
					{(screen === "fadeBox" ||
						(screen === "codeGameMaster" &&
							displayFinalChoiceSelectedMsg)) && (
						<FadeMsg
							displayBoxMsg={displayBoxMsg}
							displayScoringMsg={displayScoringMsg}
							displayFinalChoiceSelectedMsg={
								displayFinalChoiceSelectedMsg
							}
							finalChallengeSelected={finalChallengeSelected}
							displayFadeBox={displayFadeBox}
							state={gameState}
						/>
					)}
					{screen === "scoring" && (
						<>
							<Header className="bg-winners">
								Qui a fait rire ?
							</Header>
							<Header className="bg-losers">
								Qui a rigolé ?
							</Header>
							<ListPlayers
								players={players}
								page="winners"
								updatePlayerSelected={updatePlayerSelected}
								playersSelected={winnersSelected}
							/>
							<ListPlayers
								players={players}
								page="losers"
								updatePlayerSelected={updatePlayerSelected}
								playersSelected={losersSelected}
								countWinnersSelected={winnersSelected.length}
							/>
							<Button
								isBtnActive={isBtnActive}
								handleClickButton={handleClickButton}
							/>
						</>
					)}
					{screen === "finalChoice" && (
						<>
							<Header className="bg-winners">
								{finalPlayers[0].firstName}, choisis le défi
								final !
							</Header>
							<FinalChoice
								finalChallenges={finalChallenges}
								selectFinalChoice={selectFinalChoice}
								finalChallengeSelected={finalChallengeSelected}
							/>
							<Button
								isBtnActive={isBtnActive}
								handleClickButton={handleClickButton}
							/>
						</>
					)}
					{screen === "codeGameMaster" &&
						!displayFinalChoiceSelectedMsg && (
							<>
								<Header className="bg-winners">
									Code game master
								</Header>
								<div className="column-players bg-winners">
									<input
										type="text"
										value={codeGameMaster}
										className="input-code-gm"
										onChange={(e) =>
											setCodeGameMaster(e.target.value)
										}
										maxLength={3}
									/>
								</div>
								<Button
									isBtnActive={isBtnActive}
									handleClickButton={handleClickButton}
								/>
							</>
						)}
					{screen === "final" &&
						localStorage.getItem("unlock-" + appCode) === "OK" && (
							<>
								<Header className="bg-winners">Finale</Header>
								<div className="column-players bg-winners">
									{gameState ===
										"FINAL_CHALLENGE_SELECTED" && (
										<button
											className="common-button"
											onClick={startFinal}
										>
											Lancer la finale
										</button>
									)}
									{gameState === "IN_FINAL_PROGRESS" && (
										<button
											className="common-button"
											onClick={allWinners}
										>
											Les restants sont tous vainqueurs !
										</button>
									)}
									{finalPlayers.map((player) => (
										<FinalPlayer
											key={player.id}
											player={player}
											isSelected={finalPlayersSelected.includes(
												player.id
											)}
											countFinalPlayers={
												finalPlayers.length
											}
											updateFinalPlayerSelected={
												updateFinalPlayerSelected
											}
											reintegrateFinalPlayer={
												reintegrateFinalPlayer
											}
											picture={playersPictures[player.id]}
										/>
									))}
								</div>
								{gameState === "IN_FINAL_PROGRESS" && (
									<Button
										isBtnActive={isBtnActive}
										handleClickButton={handleClickButton}
									/>
								)}
							</>
						)}
				</>
			)}
		</main>
	);
}

export default App;
