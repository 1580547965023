import axios from "axios";

const PostScoring = async (gameAppCode, winnersSelected, losersSelected) => {
	try {
		let data = {
			winners: winnersSelected,
			losers: losersSelected,
		};
		const resp = await axios.post(
			process.env.REACT_APP_MAIN_API +
				"/api/game-play/player/scoring/" +
				gameAppCode,
			data
		);
		return resp.data;
	} catch (err) {
		throw new Error("Impossible d'ajouter le score");
	}
};

export default PostScoring;
