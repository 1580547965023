const ResolveFinalPlayersSelected = (finalPlayersSelected, playerId) => {
	let finalPlayersSelectedDatas = [...finalPlayersSelected];

	if (finalPlayersSelectedDatas.includes(playerId)) {
		finalPlayersSelectedDatas = finalPlayersSelectedDatas.filter(
			(playersSelectedId) => playersSelectedId !== playerId
		);
	} else {
		finalPlayersSelectedDatas.push(playerId);
	}
	return finalPlayersSelectedDatas;
};

export default ResolveFinalPlayersSelected;
