import professor from "./../../assets/img/professor.png";
import qrCodeInsta from "./../../assets/img/qr_code_insta.png";
import qrCodeLinkedin from "./../../assets/img/qr_code_in.png";
import qrCodeFb from "./../../assets/img/qr_code_fb.png";
import qrCodeGoogle from "./../../assets/img/qr_code_google.png";
import fleche from "./../../assets/img/fleche_jaune.png";

function FadeMsg({
	displayFadeBox,
	displayScoringMsg,
	displayFinalChoiceSelectedMsg,
	finalChallengeSelected,
	displayBoxMsg,
	state,
}) {
	return (
		<>
			{displayFadeBox && <div className="box-fade"></div>}
			<div
				className={"scoring-icon" + (displayScoringMsg ? " open" : "")}
			></div>
			<div
				className={
					"final-choice-msg" +
					(displayFinalChoiceSelectedMsg ? " open" : "")
				}
			>
				<p className="animate-scale">
					{finalChallengeSelected && finalChallengeSelected.label}
				</p>
			</div>
			<div className={"box-msg" + (displayBoxMsg ? " open" : "")}>
				{state === "WAITING_GAME" && (
					<p>
						<img src={professor} />
						Un peu de patience...
						<br />
						Le jeu va bientôt commencer !
					</p>
				)}
				{state === "WAITING_FINAL_CHOICE" && (
					<p>
						<img src={professor} />
						C'est parti pour la
						<br />
						GRANDE FINALE !
					</p>
				)}
				{(state === "POST_GAME" || state === "FINISHED") && (
					<>
						<p>
							Merci d'avoir joué avec nous.
							<br />A bientôt !
						</p>
						<div className="follow-us-qrcode">
							<img className="icon" src={qrCodeLinkedin} />
							<img className="icon" src={qrCodeInsta} />
							<img className="icon" src={qrCodeGoogle} />
							<p className="connect">
								<img src={fleche} />
								Restons
								<br />
								connectés !
							</p>
							<p className="google">
								<img src={fleche} />
								Tu t'es bien amusé.e ?<br />
								Aides-nous à propager
								<br />
								le pouvoir du rire en laissant
								<br />
								un avis sur Google !
							</p>
						</div>
						<div className="follow-us"></div>
					</>
				)}
			</div>
		</>
	);
}

export default FadeMsg;
