import Challenge from "./Challenge";

function FinalChoice({
	finalChallenges,
	selectFinalChoice,
	finalChallengeSelected,
}) {
	return (
		<div className="column-players bg-winners">
			{finalChallenges.map((challenge) => (
				<Challenge
					challenge={challenge}
					selectFinalChoice={selectFinalChoice}
					key={challenge.id}
					finalChallengeSelected={finalChallengeSelected}
				/>
			))}
		</div>
	);
}

export default FinalChoice;
