import Player from "../Player/Player";

function ListPlayers({
	players,
	updatePlayerSelected,
	playersSelected,
	page,
	countWinnersSelected,
}) {
	return (
		<div
			className={
				"column-players " +
				(page === "winners" ? "bg-winners" : "bg-losers")
			}
		>
			{page === "losers" && countWinnersSelected < 1 && (
				<div className="fade-losers-selection"></div>
			)}
			{players.map((player, index) => (
				<Player
					key={player.id}
					player={player}
					page={page}
					updatePlayerSelected={updatePlayerSelected}
					isSelected={playersSelected.includes(player.id)}
					countPlayers={players.length}
					index={index + 1}
				/>
			))}
		</div>
	);
}

export default ListPlayers;
