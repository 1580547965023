function Header({ className, children }) {
	return (
		<header className={className}>
			<div className="exp-container">
				<span>{children}</span>
			</div>
		</header>
	);
}

export default Header;
