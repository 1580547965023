import axios from "axios";

const ReintegratePlayer = async (gameAppCode, playerId) => {
	try {
		const resp = await axios.patch(
			process.env.REACT_APP_MAIN_API +
				"/api/game-play/player/game-master/" +
				gameAppCode +
				"/final/reintegrate-player/" +
				playerId
		);
		return resp.data;
	} catch (err) {
		throw new Error("Impossible de réintégrer ce joueur");
	}
};

export default ReintegratePlayer;
