const ResolvePlayersGroupedTeam = (mode, scenario) => {
	if (
		mode === "TEAM" &&
		scenario.hasOwnProperty("teams") &&
		scenario.teams.length > 0
	) {
		let items = {};
		scenario.teams.forEach((team) => {
			let teamPlayers = team.players;
			team.players.forEach((playerId) => {
				items[playerId] = teamPlayers;
			});
		});
		return items;
	}
	return false;
};

export default ResolvePlayersGroupedTeam;
