import axios from "axios";

const UnlockTabletGameMaster = async (gameAppCode, code) => {
	try {
		const resp = await axios.post(
			process.env.REACT_APP_MAIN_API +
				"/api/game-play/player/game-master/" +
				gameAppCode +
				"/" +
				code
		);
		return resp.data;
	} catch (err) {
		throw new Error("Impossible de dévérouiller la tablette");
	}
};

export default UnlockTabletGameMaster;
