function Challenge({ challenge, selectFinalChoice, finalChallengeSelected }) {
	const handleClickButton = () => {
		selectFinalChoice(challenge);
	};

	return (
		<div
			className={
				"challenge " +
				(finalChallengeSelected !== null &&
				finalChallengeSelected.id === challenge.id
					? "selected"
					: "")
			}
			onClick={handleClickButton}
		>
			<div
				className="picture"
				style={{ backgroundImage: `url("${challenge.picture}")` }}
			></div>
			<h3>{challenge.label}</h3>
		</div>
	);
}

export default Challenge;
