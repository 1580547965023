import loader from "./../../assets/img/loader.gif";

function Loading({ state }) {
	let text = "Chargement de la partie en cours...";
	if (state === "WAITING_PLAYERS") {
		text = "En attente des autres joueurs...";
	} else if (state === "IN_PROGRESS" || state === "DEMO") {
		text = "Validation du score...";
	}
	return (
		<div className="loader-container">
			<img src={loader} />
			<div className="text">{text}</div>
		</div>
	);
}

export default Loading;
