function Player({
	player,
	updatePlayerSelected,
	isSelected,
	page,
	countPlayers,
	index,
}) {
	let classPercent = "";
	if (countPlayers < 3) {
		classPercent = "player-container-2";
	} else if (countPlayers < 5) {
		classPercent = "player-container-4";
	} else if (countPlayers < 7) {
		classPercent = "player-container-6";
	} else if (countPlayers < 10) {
		classPercent = "player-container-9";
	} else if (countPlayers < 13) {
		classPercent = "player-container-12";
	} else if (countPlayers < 17) {
		classPercent = "player-container-16";
	}
	return (
		<>
			<div
				className={
					classPercent +
					" player-container " +
					(isSelected ? " player-selected" : "")
				}
				onClick={() => updatePlayerSelected(player.id, page)}
			>
				<div
					className="profil-image"
					style={{ backgroundImage: `url("${player.picture}")` }}
				></div>
				<div className="firstName">{player.firstName}</div>
			</div>
			{countPlayers < 5 && index % 2 === 0 && (
				<div style={{ clear: "both" }}></div>
			)}
			{countPlayers > 4 && countPlayers < 13 && index % 3 === 0 && (
				<div style={{ clear: "both" }}></div>
			)}
			{countPlayers > 12 && countPlayers < 17 && index % 4 === 0 && (
				<div style={{ clear: "both" }}></div>
			)}
		</>
	);
}

export default Player;
