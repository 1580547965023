import axios from "axios";

const AllWinners = async (gameAppCode) => {
	try {
		const resp = await axios.patch(
			process.env.REACT_APP_MAIN_API +
				"/api/game-play/player/game-master/" +
				gameAppCode +
				"/final/all-winners"
		);
		return resp.data;
	} catch (err) {
		throw new Error(
			"Impossible de déclarer vainqueurs tous les joueurs restants"
		);
	}
};

export default AllWinners;
