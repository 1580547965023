import axios from "axios";

const EliminatePlayers = async (gameAppCode, players) => {
	try {
		const resp = await axios.patch(
			process.env.REACT_APP_MAIN_API +
				"/api/game-play/player/game-master/" +
				gameAppCode +
				"/final/eliminate-players",
			{
				players: players,
			}
		);
		return resp.data;
	} catch (err) {
		throw new Error("Impossible d'éliminer ces joueurs");
	}
};

export default EliminatePlayers;
